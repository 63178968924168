import { ClientFactories, ContextStore, Store } from 'bernie-plugin-mobx';
import { NOOP_LOGGER } from 'bernie-logger';
import { GoGuidesStore } from "./goguides-store";
import { GoGuidesSource } from '../sources';

export { GoGuidesStore }

export const stores: ClientFactories = {

    context: (): Store => new ContextStore(null, NOOP_LOGGER),
    goGuidesStore: (): Store =>
    new GoGuidesStore(
        new GoGuidesSource(),
        {},
        NOOP_LOGGER
    )
};
