import {checkValue} from "components/common/helper";

export const getMoreTextIndicator = (locale) => {
  return "...";
}

export const cleanseText = (html) => {
  let stripHtml;
  try {
    stripHtml = html.toString();
    stripHtml = stripHtml.replace(/(<([^>]+)>)/ig, '');
  } catch (e) {
    stripHtml = "";
  }

  return stripHtml;
}

export const getWordLocale = (cleanText, locale) => {
  let words;
  try {
    if (["ja_JP", "zh_HK", "zh_TW"].includes(locale)) {
      words = Object.assign([], cleanText);
    } else {
      words = cleanText.split(" ");
    }
  } catch (e) {
    words = [];
  }

  return words;
}

export const wordCount = (cleanText, locale) => {
  let words = getWordLocale(cleanText, locale);
  return words.length;
}

export const generate = (cleanText, locale, maxWords) => {
  let result;
  try {
    let words = getWordLocale(cleanText, locale);
    let snippets;
    snippets = words.slice(0, maxWords);
    let stringJoin = " ";
    if (["ja_JP", "zh_HK", "zh_TW"].includes(locale)) {
      stringJoin = "";
    }
    result = snippets.join(stringJoin);
  } catch (e) {
    result = "";
  }

  return result;
}

export const generateSnippet = (html, maxWords, locale) => {
  const moreTextIndicator = getMoreTextIndicator(locale);
  if (!checkValue(html)) return html;

  let cleanText = cleanseText(html);

  let result;
  if (wordCount(cleanText, locale) <= maxWords) {
    result = cleanText;
  } else {
    result = generate(cleanText, locale, maxWords) + moreTextIndicator;
  }
  return result;
};

export const maxWord = {
  destinationHub: 70,
  categoryHub: 70,
  content: 70,
};

export const getMaxWord = (condition) => {
  return maxWord[condition];
};

export const generateSnippetCategoryHub = (text, locale = "en_GB") => {
  return generateSnippet(text, getMaxWord("categoryHub"), locale);
}

export const getWordCountWithNumberOfCardsOnDhub = (numberOfCards, cardIndex, locale) => {
  let wordCount;
  switch (numberOfCards) {
    case 1:
      wordCount = 60;
      break;
    case 2:
    case 4:
      wordCount = 40;
      break;
    case 3:
      if (cardIndex == 1) {
        wordCount = 40;
      } else {
        wordCount = 20;
      }
      break;
    case 5:
    default:
      if (cardIndex < 3) {
        wordCount = 40;
      } else {
        wordCount = 20;
      }
      break;
  }

  wordCount = addExtraWordCount(wordCount, locale);
  return wordCount;
}

export const addExtraWordCount = (wordCount, locale) => {
  if (["ja_JP", "zh_HK", "zh_TW"].includes(locale)) {
    switch (locale) {
      case "ja_JP":
        wordCount = wordCount >=40 ? wordCount + 48 : wordCount + 21;
        break;
      case "zh_HK":
        wordCount = wordCount + 20;
        break;
      case "zh_TW":
        wordCount = wordCount >=40 ? wordCount + 40 : wordCount + 24;
        break;
    }
  }
  return wordCount;
}

export const getWordCountWithNumberOfCardsOnDhubCountryLevel = (numberOfCards, cardIndex, descriptionLength, locale) => {
  let wordCount;
  switch (numberOfCards) {
    case 1:
      wordCount = 60;
      break;
    case 2:
      // if (cardIndex == 1 || descriptionLength > 0) {
      //   wordCount = 60;
      // } else {
      //   wordCount = 0;
      // }
      wordCount = 60;
      break;
    case 3:
      if (cardIndex == 1) {
        wordCount = 60;
      } else {
        wordCount = 40;
      }
      break;
    case 4:
    default:
      if (cardIndex == 1) {
        wordCount = 60;
      } else {
        wordCount = 20;
      }
      break;
  }

  wordCount = addExtraWordCount(wordCount, locale);
  return wordCount;
}

export const generateSnippetDestinationHub = (text, IsCountryLevelPage
  , numberOfCards, cardIndex
  , locale = "en_GB") => {

  let requiredWordCount;
  let result;
  if (IsCountryLevelPage) {
      requiredWordCount = getWordCountWithNumberOfCardsOnDhubCountryLevel(numberOfCards, cardIndex, text.length, locale);
      result = generateSnippet(text, requiredWordCount, locale);
  } else {
      requiredWordCount = getWordCountWithNumberOfCardsOnDhub(numberOfCards, cardIndex, locale);
      result = generateSnippet(text, requiredWordCount, locale);
  }

  return result;
}

/*
trendingNow in home and dHub nHood level
catHub
mainHighlight in dhub except nHood level
*/
export const generateSnippetContent = (text, numberOfCards, cardIndex, locale = "en_GB") => {
  let result = generateSnippet(text, getWordCountWithNumberOfCardsForContent(numberOfCards, cardIndex), locale);
  return result;
}

export const getWordCountWithNumberOfCardsForContent = (numberOfCards, cardIndex) => {
  let wordCount;
  switch (numberOfCards) {
    case 1:
      wordCount = 60;
      break;
    case 2:
    case 4:
      wordCount = 40;
      break;
    case 3:
      wordCount = cardIndex == 1 ? 40 : 20;
      break;
    case 5:
      wordCount = cardIndex < 3 ? 40 : 20;
      break;
    default: // greater than 5 cards
      wordCount = cardIndex < 2 ? 40 : 20;
  }
  return wordCount;
}

/*
catHighlight in dhub except nHood level
*/
export const generateSnippetContentHighlight = (text, numberOfCards, cardIndex, locale = "en_GB") => {
  let wordCount = getWordCountWithNumberOfCardsForContent(numberOfCards, cardIndex);
  let result = generateSnippet(text, wordCount, locale);
  return result;
}
