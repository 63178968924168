/* eslint-disable prefer-const */
import { SerializedData } from 'bernie-core';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { checkValue } from 'components/functions';
import { action, observable } from 'mobx';
import { GoGuidesSource } from 'sources';
import { getLinkToAttributionPage } from 'stores/helpers/imageAttribution-map';
import { generateSnippet, generateSnippetContent, generateSnippetDestinationHub } from 'stores/helpers/snippet-helper';
import { clickStreamExperienceMap, getDomain } from './helpers/data-maps';
import * as URL from 'url';

export class GoGuidesStore extends Store {
  @observable public isRouteChecked: boolean = false;
  @observable public pageUrlData: any;
  @observable public jsonData: any;
  @observable public isFallbackLanguage: boolean = false;
  @observable public imageDomain: string = "https://goguides.azureedge.net";

  public ClicksteamExperience: any;
  public gaiaId: string = null;

  public constructor(
    private goGuidesSource: GoGuidesSource,
    state: SerializedData = {},
    logger: Logger = NOOP_LOGGER,
  ) {
    super(state, logger);
  }

  public hydrate(data: SerializedData): void {
    delete data.goGuidesSource;
    Object.assign(this, data);
  }

  @action
  public setIsFallbackLanguage(isFallbackLanguage) {
    this.isFallbackLanguage = isFallbackLanguage;
  }

  @action
  public setIsRouteChecked(isRouteChecked) {
    this.isRouteChecked = isRouteChecked;
  }

  @action
  public setPageUrlData(pageUrlData) {
    this.pageUrlData = pageUrlData;
    this.ClicksteamExperience = clickStreamExperienceMap(pageUrlData);
  }

  @action
  public setJsonData(jsonData) {
    this.jsonData = jsonData;

    // set geoId required for page view
    if (jsonData.data && jsonData.data.body && jsonData.data.body.gaiaId) {
      this.gaiaId = jsonData.data.body.gaiaId;
    }
  }

  @action
  public fetchPage(pageUrlData: any) {
    return this.jsonData;
  }

  @action
  public async fetchPageData(pageUrlData: any): Promise<any> {
    try {
      const pageJson = await this.goGuidesSource.fetchCMSPageDataByPageUrl(pageUrlData);
      const content = this.processPageJson(pageJson, pageUrlData);

      if (this.isFallbackLanguage) {
        console.log("ggcontent: fallback content " + pageUrlData.url);
      }
      return content;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null; // or throw error
    }
  }

  private processPageJson(pageJson: any, pageUrlData: any) {
    if ('json' === pageUrlData.source || 'dda' === pageUrlData.source) {
      return this.processJsonSource(pageJson, pageUrlData);
    } else {
      return this.processOtherSource(pageJson, pageUrlData);
    }
  }

  private processJsonSource(pageJson: any, pageUrlData: any) {
    let content;
    // process json or dda source

    if (checkValue(pageJson) && checkValue(pageJson) &&
      (('json' == pageUrlData.source) || ('dda' == pageUrlData.source))) {
      content = pageJson;

      if (checkValue(content) && checkValue(content.data?.body) && checkValue(content.data.body['@type'], true)) {
        this.isFallbackLanguage = content.data.body.displayFallbackLanguageContent == true;

        switch (content.data.body['@type']) {
          case 'home': {
            pageUrlData.contentTypeId = 1;
            break;
          }
          case 'destinationhub': {
            pageUrlData.contentTypeId = 2;
            break;
          }
          case 'categoryhub': {
            pageUrlData.contentTypeId = 3;
            break;
          }
          case 'listicle': {
            pageUrlData.contentTypeId = 4;
            break;
          }
          case 'longform': {
            pageUrlData.contentTypeId = 5;
            break;
          }
        }

        if (content.data.body['relatedStories'] && (content.data.body['relatedStories'].length > 0)) {
          let adjustedData = {
            relatedStories: content.data.body['relatedStories'].map((item) => {
              const { imageUrl, ...rest } = item;

              return {
                ...rest,
                image: {
                  imageUrl: imageUrl,
                },
              };
            }),
          };
          content.data.body['relatedStories'] = adjustedData.relatedStories;
        }
      }
    }

    return content;
  }

  private processOtherSource(pageJson: any, pageUrlData: any) {
    let content;
    // process api source
    if (checkValue(pageJson) && checkValue(pageJson) && checkValue(pageJson.contentType)) {
      //https://uk.hotels.com/capsapi/template-cplite/goguides/best-things-to-do-after-dinner-marrakech/en_US/0?ispreview=1&locale=en_GB&pos=HCOM_UK&siteid=300000005
      //https://uk.hotels.com/capsapi/goguides/morocco/10-best-things-to-do-after-dinner-in-marrakech?locale=en_GB&pos=HCOM_UK&siteid=300000005
      let body = pageJson;

      let skipPage = false;
      let checkEnv = "";
      try {
        if (
          (typeof process  != 'undefined')
          && (typeof process.env  != 'undefined')
          && (typeof process.env.EXPEDIA_ENVIRONMENT  != 'undefined')
        ) {
          checkEnv = process.env.EXPEDIA_ENVIRONMENT
        }
      } catch(e) {console.log(e);}
      if ("test" != checkEnv) {
        skipPage = !body.isLive;
      }

      if (skipPage) {
        body = {};
      } else {
        let contentType = "";
        switch (body.contentType) {
          case 'goGuidesHome': {
            pageUrlData.contentTypeId = 1;
            contentType = 'home';
            break;
          }
          case 'goGuidesDestinationPageTemplate': {
            pageUrlData.contentTypeId = 2;
            contentType = 'destinationhub';
            break;
          }
          case 'goGuidesDestinationThemeTemplate': {
            pageUrlData.contentTypeId = 3;
            contentType = 'categoryhub';
            break;
          }
          case 'goGuidesListicleTemplate': {
            pageUrlData.contentTypeId = 4;
            contentType = 'listicle';
            break;
          }
          case 'goGuidesLongFormTemplate': {
            pageUrlData.contentTypeId = 5;
            contentType = 'longform';
            break;
          }
        }

        if (checkValue(contentType)) {
          body['@type'] = contentType;
        }

        this.generateImage(body);
        this.generateFromGaiaData(body);

        body.locale = pageUrlData.locale;
        // need help about Umbraco locale
        if (body.isDefaultLocaleDataReturned) {
          this.isFallbackLanguage = true;
          let fallBackDomain = getDomain(pageUrlData.locale);
          body.locale = fallBackDomain.fallbackLocale;
        }

        body.displayFallbackLanguageContent = this.isFallbackLanguage == true;

        if (body && body.introText && checkValue(body.introText.markup)) {
          body.introText = body.introText.markup;
        }

        if ('home' == contentType) {
          //hardcode for homePage
          body['contentSource'] = 'cit_original';

          // TopDestinations
          this.generateTopDestinations(body);

          // FeaturedStories
          this.generateFeaturedStories(body, "items");
        } else if ('destinationhub' == contentType) {
          // TopDestinations
          // State usa/washington
          // Region china/hainan
          if (["continent", "country", "region", "state"].includes(body.destinationType)) {
            this.generateTopDestinations(body);
          }
          // Freetext
          this.generateFreetext(body);

          // FeaturedStories
          if (["country", "region", "state"].includes(body.destinationType)) {
            // this.generateFeaturedStories(body);
          }

          // Trending now
          if (["continent", "neighborhood"].includes(body.destinationType)) {
            this.generateFeaturedStories(body, "trendingNow");
          }

          // Main HighLight, Category HighLight
          if (["country", "region", "state", "city"].includes(body.destinationType)) {
            this.generateCategoryHighLight(body);
          }

          // WhatsAround
          this.generateWhatsAround(body);

          // OtherCategories will be json
          this.generateOtherCategories(body);

          // Stories from contributors [contributorsStories]
          this.generateContributorsStories(body);

          // AlsoPopular
          this.generateAlsoPopular(body);

          // Other Neighbourhoods2
          if (["city", "neighborhood"].includes(body.destinationType)) {
            this.generateOtherNeighbourhoods(body);
          }
        } else if ('categoryhub' == contentType) {
          // FeaturedStories
          this.generateFeaturedStories(body, "items");

          if (body.categoryCode && (body.categoryCode.length>0) && checkValue(body.categoryCode[0].properties?.parentCode, true)) {
            body.categoryCode = body.categoryCode[0].properties.parentCode;
          }

          // categoryCodes
          this.generateCategoryCodes(body);

          //OtherCategories
          this.generateOtherCategories(body);

          // Stories from contributors [contributorsStories]
          this.generateContributorsStories(body);

          // AlsoPopular
          this.generateAlsoPopular(body);

          // WhatsAround
          this.generateWhatsAround(body);
        } else if ('listicle' == contentType) {
          this.generateRelatedStories(body);
          this.generateWriter(body);

          body.tagCodeNames = body.segmentCodes;
          body.omnitureSiteSectionCode = body.listicleGroup;

          // ItemBucket
          let tmpItemBucket = this.generateItemBucket(body);
          delete body.items;
          if (checkValue(tmpItemBucket) && Array.isArray(tmpItemBucket) && (tmpItemBucket.length > 0)) {
            body.items = tmpItemBucket;
          }

          // categoryCodes
          this.generateCategoryCodes(body);

          if (checkValue(body.hideNumber)) {
            body["features"] = {
              "hideNumber": true
            }
          }

        } else

        if ('longform'==contentType) {
          this.generateRelatedStories(body);
          this.generateWriter(body);

          let tmpGeo = this.generateGeo(body.latLng);
          if (checkValue(tmpGeo)
            || checkValue(body.location, true)
            || checkValue(body.openingHours, true)
            || checkValue(body.phoneNumber, true)
            || checkValue(body.price, true)
          ) {
            let info = {};
            if (checkValue(body.location, true)) {
              info["location"] = body.location;
            }
            if (checkValue(body.openingHours, true)) {
              info["openingHours"] = body.openingHours;
            }
            if (checkValue(body.phoneNumber, true)) {
              info["phoneNumber"] = body.phoneNumber;
            }
            if (checkValue(body.price, true)) {
              info["price"] = body.price;
            }
            if (checkValue(tmpGeo)) {
              info["geo"] = tmpGeo;
            }
            body.info = info;
          }

          //content
          this.generateContent(body);

          // categoryCodes
          this.generateCategoryCodes(body);

          // categoryLinks - must process this after this.generateCategoryCodes(body);
          this.generateCategoryLinks(body);
        }
      }
      content = {
        data: {
          body: body,
        },
      };
    }
    return content;
  }

  public generateWriter(body: any) {
    const writer = body.writer && body.writer.length > 0 ? body.writer : [];
    if (writer.length>0) {
      let item = writer[0];

      const writerName = item.properties.writerName ? item.properties.writerName : '';
      const imageUrl = item.properties.image && item.properties.image.length > 0
        ? this.imageDomain + item.properties.image[0].url
        : null;
      const descriptorKey = item.properties.descriptorKey ? item.properties.descriptorKey : '';

      if (writerName !== '' && descriptorKey !== '') {
        let tmpWriter = {
          "name": writerName,
          "imageUrl": imageUrl,
          "descriptorKey": descriptorKey
        };

        delete body.writer;
        body.writer = tmpWriter;
      }
    }
  }

  public generateRelatedStories(body: any) {
    let tmpStories = [];
    if (body && body.stories) {
      tmpStories = this.generateTeaserList(body.stories, {
        hasPropertiesNode: true,
        country: true,
        isRelatedStories: true,
      });
    }
    delete body.stories;
    if (tmpStories.length > 0) {
      body.relatedStories = tmpStories;
    }
  }

  public removeQueryString(url: any) {
    let newUrl = checkValue(url, true) ? url.split('?')[0] : url;
    let checkEnv = "";
    try {
      if (
        (typeof process  != 'undefined')
        && (typeof process.env  != 'undefined')
        && (typeof process.env.EXPEDIA_ENVIRONMENT  != 'undefined')
      ) {
        checkEnv = process.env.EXPEDIA_ENVIRONMENT
      }
    } catch(e) {console.log(e);}
    if ("test" == checkEnv) {
      newUrl = newUrl.replace("/go", "/goguides");
    }
    return newUrl;
  }

  public removeQueryStringFromTargetUrl(json: any) {
    if (typeof json === 'object' && json !== null) {
      if (Array.isArray(json)) {
        json.forEach(item => this.removeQueryStringFromTargetUrl(item));
      } else {
        for (let key in json) {
          if ('targetUrl' == key) {
            json[key] = this.removeQueryString(json[key]);
          } else {
            this.removeQueryStringFromTargetUrl(json[key]);
          }
        }
      }
    }
  }

  public generateDestinationList(body: any) {
    body.destinationList = null;
    if (body && body.gaia && body.gaia[0]
      && checkValue(body.gaia[0].destinationList)
    ) {
      try {
        let json = body.gaia[0].destinationList;
        if (checkValue(json) && (Object.keys(json).length > 0)) {
          this.removeQueryStringFromTargetUrl(json);
          body.destinationList = json;
        }
      } catch(e) {
      }
    }
  }

  public generateBreadCrumb(body: any) {
    body.breadcrumb = [];
    if (body && ('home' != body['@type'])
      && body.gaia && body.gaia[0]
      && checkValue(body.gaia[0].breadcrumb)) {
      try {
        // let json = JSON.parse(body.gaia[0].breadcrumb);
        let json = body.gaia[0].breadcrumb;
        if (checkValue(json) && (json.length > 0)) {
          this.removeQueryStringFromTargetUrl(json);
          body.breadcrumb = json;

          let gaiaNode = body.gaia[0];
          let destinationPage = gaiaNode.destinationPage;
          let tmpDestination = {};
          if (checkValue(destinationPage) && (destinationPage.length > 0)) {
            let destinationPageContent = destinationPage[0].properties;
            if (checkValue(destinationPageContent)) {
              tmpDestination['name'] = destinationPageContent.h1;
              tmpDestination['targetUrl'] = this.getGoGuidesUrl(destinationPageContent);
              let gaiaDhub = "";
              try {
                gaiaDhub = destinationPageContent['gaia'][0]['properties']['gaiaId']
              } catch(e) {
              }

              if (checkValue(tmpDestination['targetUrl'])
                && (body.gaia[0]['gaiaId']==gaiaDhub)
              ) {
                body.breadcrumb[body.breadcrumb.length - 1] = tmpDestination;
              }
            }
          }
        }
      } catch(e) {
      }
    }
  }

  public generateMenu(body: any) {
    body.menu = null;
    // homePage and content World level will have body.destinationId is null
    if (body && body.gaia && body.gaia[0] && checkValue(body.destinationId)) {
      let menu = {
        "home": {
          "name": "Go Guides",
          "targetUrl": this.getGoGuidesUrl({country: null, slug: ""})
        }
      };
      let gaiaNode = body.gaia[0];
      let tmpDestination = {};
      let tmpNodeName;

      // only work on this if there's breadcrumb
      if (body.breadcrumb.length > 0) {
        if ('neighborhood' == body.destinationType) {
          tmpNodeName = body.breadcrumb[body.breadcrumb.length - 2];
        } else {
          tmpNodeName = body.breadcrumb[body.breadcrumb.length - 1];
        }
        if (checkValue(tmpNodeName['name'], true)) {
          tmpDestination['name'] = tmpNodeName['name'];
        }
        if (checkValue(tmpNodeName['targetUrl'], true)) {
          tmpDestination['targetUrl'] = tmpNodeName['targetUrl'];
        }
        if (checkValue(tmpDestination['name'])) {
          menu['destination'] = tmpDestination;
        }
      }

      const categoryList = [
        { nodeName: "thingsToDoHub", categoryCode: "things_to_do" },
        { nodeName: "restaurantHub", categoryCode: "food" },
        { nodeName: "shoppingHub", categoryCode: "shopping" },
        { nodeName: "nightlifeHub", categoryCode: "nightlife" },
        { nodeName: "toursHub", categoryCode: "tours" },
        { nodeName: "informationHub", categoryCode: "information" },
        { nodeName: "blogHub", categoryCode: "blogs" }
      ];

      let categories = [];
      categoryList.map((category, index) => {
        let tmpCategory = {};
        let categoryPage = gaiaNode[category.nodeName];

        if (categoryPage && categoryPage.length > 0) {
          //temporary fix
          let categoryPageContent = categoryPage[0].properties;
          if (checkValue(categoryPageContent.slug)) {
            tmpCategory['categoryKey'] = category.categoryCode;
            tmpCategory['targetUrl'] = this.getGoGuidesUrl(categoryPageContent);
            categories.push(tmpCategory);
          }
        }
      });

      if (categories.length > 0) {
        menu['category'] = categories;
      }
      body.menu = menu;
    }
  }

  private getSortValueForCategoryLinks(sortKey, key) {
    return sortKey[key] || Infinity;
  }

  public generateCategoryLinks(body: any) {
    let tmpCategoryLinks = [];
    if (body && checkValue(body.categoryCodes)) {
      let tmpCategoryKeys = Object.keys(body.categoryCodes);
      let tmpSegmentCodes = body.segmentCodes || [];
      let newSegmentCodes = tmpSegmentCodes.map((item, index) => {
        let newItem = item;
        newItem = newItem == "shoppers" ? "shopping" : newItem;
        newItem = newItem == "nightlife_b595ae4" ? "nightlife" : newItem;
        return newItem;
      });
      // let categoryKeys = [...tmpCategoryKeys, ...newSegmentCodes];//[...new Set([...a, ...b])];
      let categoryKeys = [...new Set([...tmpCategoryKeys, ...newSegmentCodes])];

      let categoryLinks = body.categoryLinks || [];
      if (categoryKeys.length > 0) {
        let sortKey = {
          things_to_do: 1,
          food: 3,
          shopping: 4,
          nightlife: 5,
          tours: 7,
          information: 8,
          blogs: 9,
          hotels: 10,
        }
        let maximumShowCategoryLinks = 3;
        categoryKeys.sort((a, b) => this.getSortValueForCategoryLinks(sortKey, a) - this.getSortValueForCategoryLinks(sortKey, b));
        categoryKeys.map((categoryKey, index) => {
          if (checkValue(sortKey[categoryKey])) {
            let tmpCategoryLink = {
              name: categoryKey,
            };
            for (const categoryLink of categoryLinks) {
              if (categoryKey == categoryLink.properties?.categoryCode) {
                tmpCategoryLink['targetUrl'] = this.getGoGuidesUrl(categoryLink.properties);
                break;
              }
            }
            if (tmpCategoryLinks.length < maximumShowCategoryLinks) {
              tmpCategoryLinks.push(tmpCategoryLink);
            }
          }
        });

        if (tmpCategoryLinks.length > 0) {
          tmpCategoryLinks.sort((a, b) => sortKey[a.name] - sortKey[b.name]);
        }
      }
    }
    delete body.categoryLinks;
    if (tmpCategoryLinks.length > 0) {
      body.categoryLinks = tmpCategoryLinks;
    }
  }

  public generateContent(body: any) {
    if (body && body.content) {
      body.content.map((item, index) => {
        if ("longFormImage"==item.contentType) {
          this.generateImage(item);
          this.generateImageAttribution(item);
        } else

        if ("longFormText"==item.contentType) {
          if (checkValue(item.html?.markup, true)) {
            item.html.paragraph = item.html.markup;
            delete item.html.markup;
          }
        }
      });
    }
  }

  public generateTeaserList(items: any, attributeParam = {}) {
    const attributeDefault = {
      hasPropertiesNode: false
      , country: false
      , city: false
      , snippet: false
      , isTopDestination: false
      , isTrendingNow: false
      , isContributorsStories: false
      , isOtherNeighbourhoods: false
      , isRelatedStories: false
      , pageDestinationType: ""
      , pageLocale: ""
    };

    const attribute = Object.assign({}, attributeDefault, attributeParam);
    let tmpTeaserList = [];
    if (items && (items.length>0)) {
      items.map((item, index) => {
        let isOk = true;
        let itemProperties = attribute.hasPropertiesNode ? item.properties : item;
        let tmpTeaser = {};
        tmpTeaser['name'] = itemProperties.h1;
        tmpTeaser['targetUrl'] = this.getGoGuidesUrl(itemProperties);

        this.generateImage(itemProperties);
        tmpTeaser['image'] = itemProperties.image;

        if (attribute.snippet) {
          let introText = this.getIntroText(itemProperties);
          // top destination, other neighbourhoods
          if (attribute.isTopDestination || attribute.isOtherNeighbourhoods) {
            let destinationType = checkValue(attribute.pageDestinationType, true) ? attribute.pageDestinationType : "";
            let isCountryLevelPage = "country" == destinationType
            tmpTeaser['snippetText'] = generateSnippetDestinationHub(introText
              , isCountryLevelPage
              , items.length
              , index + 1
              , attribute.pageLocale
            );
          }

          // Trending Now
          if (attribute.isTrendingNow) {
            tmpTeaser['snippetText'] = generateSnippetContent(introText, items.length, index + 1, attribute.pageLocale);
          }
        }

        // alsoPopular
        if (attribute.country) {
          tmpTeaser['countryName'] = itemProperties.countryName;
        }

        // alsoPopular
        if (attribute.city) {
          tmpTeaser['cityName'] = itemProperties.cityName;
        }

        // contributors stories
        if (attribute.isContributorsStories) {
          isOk = "blogs" == itemProperties.listicleGroup;
        }

        // relatedStories
        if (attribute.isRelatedStories) {
          tmpTeaser['isBlog'] = "blogs" == itemProperties.listicleGroup;
        }

        // Other Neighbourhoods
        if (attribute.isOtherNeighbourhoods) {
          if (itemProperties.gaia && (itemProperties.gaia.length>0)
            && checkValue(itemProperties.gaia[0].properties?.gaiaId, true)
          ) {
            tmpTeaser['seeAllPropertiesUrl'] = "/Hotel-Search?regionId=" + itemProperties.gaia[0].properties.gaiaId;
          }
        }

        isOk = isOk && checkValue(tmpTeaser['name'], true);
        if (isOk) {
          tmpTeaserList.push(tmpTeaser);
        }
      });
    }
    return tmpTeaserList;
  }

  public generateContributorsStories(body: any) {
    let tmpContributorsStories = [];
    if (body && body.contributorStories) {
      tmpContributorsStories = this.generateTeaserList(body.contributorStories, {
        hasPropertiesNode: true,
        isContributorsStories: true,
      });
    }
    delete body.contributorStories;
    if (tmpContributorsStories.length > 0) {
      body.contributorsStories = {
        storiesList: tmpContributorsStories
      };
      if (checkValue(body.seeAllStoriesLink, true)) {
        body.contributorsStories.seeAllStoriesLink = {
          targetUrl: this.removeQueryString(body.seeAllStoriesLink)
        }
      }
    }
  }

  public generateOtherNeighbourhoods(body: any) {
    let tmpOtherNeighbourhoods = [];
    if (body && body.otherNeighbourhoods2Items) {
      tmpOtherNeighbourhoods = this.generateTeaserList(body.otherNeighbourhoods2Items, {
        hasPropertiesNode: true,
        snippet: true,
        isOtherNeighbourhoods: true
      });
    }
    delete body.otherNeighbourhoods2Items;
    if (tmpOtherNeighbourhoods.length > 0) {
      body.otherNeighbourhoods2 = {
        neighbourhoodsDestinationName: body.otherNeighbourhoods2DestinationName,
        neighbourhoodsSeeAllUrl: this.getUrlWithParameter(body.otherNeighbourhoods2SeeAllUrl, ["regionId"]),
        neighbourhoodsList: tmpOtherNeighbourhoods
      };
    }
  }

  public getUrlWithParameter(link: any, allowParams: any) {
    let newUrl = link;
    try {
      let parsedUrl = URL.parse(link, true);
      let query = parsedUrl.query;
      let newQuery = {};
      for (let param of allowParams) {
        if (query[param] !== undefined) {
          newQuery[param] = query[param];
        }
      }
      newUrl = `${parsedUrl.pathname}?${new URLSearchParams(newQuery).toString()}`;
    } catch (e) {
    }
    return newUrl;
  }

  public generateAlsoPopular(body: any) {
    let tmpAlsoPopulars = [];
    if (body && body.alsoPopular) {
      tmpAlsoPopulars = this.generateTeaserList(body.alsoPopular, {
        country: true,
        city: true,
        hasPropertiesNode: true,
      });
    }
    delete body.alsoPopular;
    if (tmpAlsoPopulars.length > 0) {
      body.alsoPopular = tmpAlsoPopulars;
    }
  }

  public generateCategoryCodes(body: any) {
    let tmpCategoryCodes = {};
    if (body && body.categoryCodes) {
      body.categoryCodes.map((item, index) => {
        let itemProperties = item.properties;
        let parentCode = itemProperties.parentCode;
        let code = itemProperties.code;
        if (checkValue(parentCode, true)) {
          if (!(parentCode in tmpCategoryCodes)) {
            tmpCategoryCodes[parentCode] = [];
          }
          if (checkValue(code, true)) {
            tmpCategoryCodes[parentCode].push(code);
          }
        } else {
          if (!(code in tmpCategoryCodes)) {
            tmpCategoryCodes[code] = [];
          }
        }
      });
    }
    delete body.categoryCodes;
    if (Object.keys(tmpCategoryCodes).length > 0) {
      body.categoryCodes = tmpCategoryCodes;
    }
  }

  public generateWhatsAround(body: any) {
    let tmpWhatsAround = [];
    if (body && (body.whatsAround)) {
      tmpWhatsAround = this.generateTeaserList(body.whatsAround, {
        hasPropertiesNode: true,
      });
    }
    delete body.whatsAround;
    if (tmpWhatsAround.length > 0) {
      body.whatsAround = tmpWhatsAround;
    }
  }

  public generateCategoryHighLight(body: any) {
    body.mainHighlight = [];
    body.categoryHighlight = [];
    const categoryList = [
      { nodeName: "thingsToDoHub", categoryCode: "THINGS_TO_DO" },
      { nodeName: "restaurantHub", categoryCode: "RESTAURANT" },
      { nodeName: "shoppingHub", categoryCode: "SHOPPING" },
      { nodeName: "nightlifeHub", categoryCode: "NIGHTLIFE" },
      { nodeName: "informationHub", categoryCode: "INFORMATION" }
    ];
    if (body && body.gaia && (body.gaia.length > 0)) {
      categoryList.map((category, index) => {
        let content = body.gaia[0][category.nodeName];
        if (content && content.length>0) {
          let tmpCategoryHighLight = {};
          let tmpContent = content[0].properties;
          if ("goGuidesDestinationThemeTemplate" == content[0].contentType) {
            tmpCategoryHighLight['items'] = tmpContent['items'];
            tmpCategoryHighLight['snippetText'] = generateSnippet(this.getIntroText(tmpContent), 70, body.locale);
            tmpCategoryHighLight['sellAllLink'] = {
              linkText: "Show more",
              targetUrl: this.getGoGuidesUrl(tmpContent)
            };
          } else {
            let tmpItems = {};
            tmpItems['properties'] = content[0].properties;
            tmpCategoryHighLight['items'] = [ tmpItems ];
          }

          let tmpCategoryHighLightItems;
          let tmpItems = tmpCategoryHighLight['items'];
          const maxItem = ("thingsToDoHub" == category.nodeName) ? 9 : 3;
          if (tmpItems && (tmpItems.length > maxItem)) {
            tmpCategoryHighLightItems = tmpItems.slice(0, maxItem);
          } else {
            tmpCategoryHighLightItems = tmpItems;
          }

          let tmpNode = {
            items: tmpCategoryHighLightItems,
            locale: body.locale,
          }

          delete tmpCategoryHighLight['items'];
          tmpCategoryHighLight['items'] = this.generateTeaserList(tmpNode.items, {
            hasPropertiesNode: true,
            snippet: true,
            isTrendingNow: true,
            pageLocale: tmpNode.locale,
          });

          tmpCategoryHighLight['categoryCode'] = category.categoryCode;
          if ("thingsToDoHub" == category.nodeName) {
            body.mainHighlight.push(tmpCategoryHighLight);
          } else {
            body.categoryHighlight.push(tmpCategoryHighLight);
          }
        }
      });
    }
  }

  public generateFeaturedStories(body: any, nodeName: any) {
    let tmpFeaturedStories = [];
    if (body && body[nodeName]) {
      let attributeParam = {
        snippet: true,
        isTrendingNow: true,
        pageLocale: body.locale,
      };

      if ("trendingNow" == nodeName) {
        attributeParam["hasPropertiesNode"] = true;
      }

      tmpFeaturedStories = this.generateTeaserList(body[nodeName], attributeParam);
    }
    delete body[nodeName];
    delete body.items;
    if (tmpFeaturedStories.length > 0) {
      body.items = tmpFeaturedStories;
    }
  }

  public generateFreetext(body: any) {
    if ( checkValue(body.freeTextHeaderKey, true) && checkValue(body.freeTextContent?.markup, true) ) {
      body.freeText = {
        headerKey: body.freeTextHeaderKey,
        content: body.freeTextContent.markup
      }
    }
  }

  public getGoGuidesUrl(item: any) {
    let goGuidesUrl = "/go";
    let checkEnv = "";
    try {
      if (
        (typeof process  != 'undefined')
        && (typeof process.env  != 'undefined')
        && (typeof process.env.EXPEDIA_ENVIRONMENT  != 'undefined')
      ) {
        checkEnv = process.env.EXPEDIA_ENVIRONMENT
      }
    } catch(e) {console.log(e);}
    if ("test" == checkEnv) {
      goGuidesUrl = "/goguides";
    }
    if (checkValue(item.country)) {
      goGuidesUrl = goGuidesUrl + "/" + item.country;
    }
    goGuidesUrl = goGuidesUrl + "/" + item.slug;
    return goGuidesUrl;
  }

  public generateTopDestinations(body: any) {
    let tmpTopDestinations = [];
    let topDestinations = body.topDestinations;
    let hasPropertiesNode = true;
    if ("home" == body['@type']) {
      topDestinations = body.destinationItems;
      hasPropertiesNode = false;
    }
    if (body && topDestinations) {
      tmpTopDestinations = this.generateTeaserList(topDestinations, {
        hasPropertiesNode: hasPropertiesNode,
        snippet: true,
        isTopDestination: true,
        pageDestinationType: body.destinationType,
        pageLocale: body.locale,
      });
    }
    delete body.destinationItems;
    delete body.topDestinations;
    if (tmpTopDestinations.length > 0) {
      body.topDestinations = tmpTopDestinations;
    }
  }

  public generateOtherCategories(body: any) {
    body.otherCategories = null;
    if (body && body.gaia && (body.gaia.length > 0)) {
      let gaiaNode = body.gaia[0];

      const categoryList = [
        { nodeName: "thingsToDoHub", categoryCode: "things_to_do", imageUrl: "https://a.cdn-hotels.com/gdcs/production198/d1399/be08fb5d-2d67-4461-a6b2-63d3c40014b7.jpg" },
        { nodeName: "restaurantHub", categoryCode: "food", imageUrl: "https://a.cdn-hotels.com/gdcs/production40/d1052/52093590-9f56-42c5-a147-1770c0fcd493.jpg" },
        { nodeName: "shoppingHub", categoryCode: "shopping", imageUrl: "https://a.cdn-hotels.com/gdcs/production196/d847/a1419d7f-e088-4b46-8822-375da35ddb22.jpg" },
        { nodeName: "nightlifeHub", categoryCode: "nightlife", imageUrl: "https://a.cdn-hotels.com/gdcs/production96/d1863/f96b8e99-6ced-4974-badf-06a6ccef6ce7.jpg" },
        { nodeName: "toursHub", categoryCode: "tours", imageUrl: "https://a.cdn-hotels.com/gdcs/production49/d903/f8962492-194e-4453-8275-904ca4c04dba.jpg" },
        { nodeName: "informationHub", categoryCode: "information", imageUrl: "https://a.cdn-hotels.com/gdcs/production105/d960/eec79967-eccf-4d8a-9de1-55e86a4f7108.jpg" },
        { nodeName: "blogHub", categoryCode: "blogs", imageUrl: "https://a.cdn-hotels.com/gdcs/production76/d1321/967f56c4-28b5-40e4-b5a0-98e841b65c40.jpg" }
      ];

      let categories = [];
      categoryList.map((category, index) => {
        let tmpCategory = {};
        let categoryPage = gaiaNode[category.nodeName];

        if (categoryPage && categoryPage.length > 0 && categoryPage[0].properties.slug) {
          let tmpContent = categoryPage[0].properties;
          tmpCategory['image'] = {};
          if ("goGuidesDestinationThemeTemplate" == categoryPage[0].contentType) {
            if (checkValue(tmpContent)) {
              this.generateImage(tmpContent);
              tmpCategory['image'] = tmpContent.image;
            }
          }

          if (!checkValue(tmpCategory['image']['imageUrl'])) {
            tmpCategory['image']['imageUrl'] = category.imageUrl;
          }
          tmpCategory['targetUrl'] = this.getGoGuidesUrl(tmpContent);
          tmpCategory['categoryCode'] = category.categoryCode;
          categories.push(tmpCategory);
        }
      });

      if (categories.length > 0) {
        body['otherCategories'] = {"category": categories};
      }
    }
  }

  public ifNullDeleteThisNode(item: any, name: any) {
    if (!checkValue(item[name])) {
      delete item[name];
    }
  }

  public generateImageAttribution(item: any) {
    if (checkValue(item.image) && checkValue(item.attributionVersion, true)) {
      let link = checkValue(item.attributionVersion, true) ?
        getLinkToAttributionPage(item.attributionVersion) : null;
      let url;
      if (checkValue(link)) {
        url = link.url;
      }

      item.image.attribution = {
        "photographerName": checkValue(item.imageCredit) ? item.imageCredit : "",
        "linkToOriginal": item.linkToOriginal,
        "attributionVersion": item.attributionVersion,
        "linkToAttributionPage": url
      }
    }
  }

  public getIntroText(item: any) {
    let introText;
    if (item && item.introText && checkValue(item.introText.markup)) {
      introText = item.introText.markup;
    }
    if ("<p><br></p>" == introText) {
      introText = "";
    }
    return introText;
  }

  public generatePageDestinationInfo(body: any) {
    body.destinationId = null;
    body.gaiaId = null;
    body.destinationName = null;
    if (body && body.gaia && body.gaia[0]) {
      if (checkValue(body.gaia[0].destinationId)) {
        body.destinationId = body.gaia[0].destinationId;
      }

      if (checkValue(body.gaia[0].gaiaId)) {
        body.gaiaId = body.gaia[0].gaiaId;
      }

      if (checkValue(body.gaia[0].name)) {
        body.destinationName = body.gaia[0].name;
      }
    }
  }

  public generateFromGaiaData(body: any) {
    this.generatePageDestinationInfo(body);
    this.generateBreadCrumb(body);// BreadCrumb must process before menu
    this.generateMenu(body);// menu must process after BreadCrumb
    this.generateDestinationList(body);

  }

  public generateImage(item: any) {
    let tmpImage;
    if (item && item.image && item.image[0] && checkValue(item.image[0].url)) {
      tmpImage = { imageUrl: this.generateImageUrl(item.image[0].url) };
    }

    delete item.image;
    if (checkValue(tmpImage)) {
      item.image = tmpImage;
    }
  }

  public generateGeo(latlng: string) {
    let geo;
    if (checkValue(latlng, true)) {
      const splitLatlng = latlng.split(',');
      if (splitLatlng.length==2) {
        geo = {
          "coord": {
            "lat": splitLatlng[0].trim(),
            "lon": splitLatlng[1].trim()
          }
        };
      }
    }
    return geo;
  }

  public generateItemBucket(body: any) {
    let tmpItemBucket = [];
    if (body && body.items) {
      body.items.map((item, index) => {
        let image;
        item.name = item.title;
        delete item.title;
        item.tagLine = item.tagline;
        delete item.tagline;
        this.generateImage(item);
        this.generateImageAttribution(item);

        if (item.description && checkValue(item.description.markup, true)) {
          let description = item.description.markup;
          delete item.description;
          item.description = description;
        } else {
          item.description = "";
        }

        let tmpGeo = this.generateGeo(item.latlng);
        if (checkValue(tmpGeo)) {
          item.geo = tmpGeo;
          delete item.latlng;
        }

        // categoryCodes
        this.generateCategoryCodes(item);

        delete item.goodFor;
        let segmentCodes = item.segmentCodes;
        if (checkValue(segmentCodes)) {
          segmentCodes = segmentCodes.filter(segmentCode => segmentCode !== "*nosegments");
          if (segmentCodes.length > 0) {
            segmentCodes.sort();
          }
          let goodFor = [];
          segmentCodes.map((segmentCode, index) => {
            goodFor.push({ "segmentCode": segmentCode });
          });
          if (goodFor.length > 0) {
            item.goodFor = goodFor;
          }
        }

        if (checkValue(item.itemLink) && (item.itemLink.length > 0)) {
          let itemLinkContent = item.itemLink[0].properties;
          if (checkValue(itemLinkContent)) {
            item['targetUrl'] = this.getGoGuidesUrl(itemLinkContent)
            delete item.itemLink;
          }
        }

        // this.ifNullDeleteThisNode(item, "nearbyProperties");
        // this.ifNullDeleteThisNode(item, "pdpLink");
        // this.ifNullDeleteThisNode(item, "openingHours");
        // this.ifNullDeleteThisNode(item, "location");
        // this.ifNullDeleteThisNode(item, "phoneNumber");
        // this.ifNullDeleteThisNode(item, "price");

        tmpItemBucket.push(item);
      });
    }
    if (tmpItemBucket.length > 0) {
      return tmpItemBucket;
    } else {
      return undefined;
    }
  }

  @action
  public fetchHero(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpH1 = goGuidesFetchedData.data.body.h1;
      let tmpH2 = goGuidesFetchedData.data.body.h2;
      let tmpImage = goGuidesFetchedData.data.body.image;
      let tmpPageType = goGuidesFetchedData.data.body['@type'];

      //process cms data
      //assign value
      const h1 = tmpH1;
      const h2 = tmpH2;
      const image = tmpImage;
      const pageType = tmpPageType;

      let pageCategory;
      let tmpPageCategory = goGuidesFetchedData.data.body.categoryLinks;
      if (checkValue(tmpPageCategory) && (tmpPageCategory.length > 0)) {
        const adjustedData = {
          categoryLinks: tmpPageCategory.map((item) => {
            const { name, ...rest } = item;

            return {
              ...rest,
              segmentCode: name.toLowerCase().replaceAll(" ", "_"),
            };
          }),
        };

        pageCategory = adjustedData.categoryLinks;
      }

      //prepare prop
      result = {
        h1,
        h2,
        image,
        pageType,
        pageCategory,
      };
    }
    return result;
  }

  @action
  public fetchGaiaIdAndDestinationName(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    if (
      checkValue(goGuidesFetchedData) &&
      checkValue(goGuidesFetchedData.data.body.gaiaId) &&
      checkValue(goGuidesFetchedData.data.body.destinationName)
    ) {
      return {
        gaiaId: goGuidesFetchedData.data.body.gaiaId,
        destinationName: goGuidesFetchedData.data.body.destinationName,
      };
    }
  }

  @action
  public fetchBreadcrumb(pageUrlData: any): any {
    const goGuidesFetchedData = this.jsonData;

    let result = [];
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let breadcrumb = goGuidesFetchedData.data.body.breadcrumb;

      //process cms data
      //assign value

      if (checkValue(breadcrumb) && (breadcrumb.length > 0)) {
        //prepare prop
        result = breadcrumb;
      }
    }

    return result;
  }

  @action
  public fetchNavigation(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpMenu = goGuidesFetchedData.data.body.menu;

      //process cms data
      //assign value

      if (checkValue(tmpMenu) && (Object.keys(tmpMenu).length > 0)) {
        //prepare prop
        result = tmpMenu;
      }
    }
    return result;
  }

  @action
  public fetchIntrotext(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpIntroText = goGuidesFetchedData.data.body.introText;
      let tmpPageType = goGuidesFetchedData.data.body['@type'];

      //process cms data
      //assign value
      const introText = tmpIntroText;
      const pageType = tmpPageType;

      if (checkValue(introText)) {
        //prepare prop
        result = {
          introText,
          pageType,
        };
      }
    }
    return result;
  }

  @action
  public fetchTopDestinations(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpTopDestinations = goGuidesFetchedData.data.body.topDestinations;
      let tmpPageType = goGuidesFetchedData.data.body['@type'];
      let tmpDestinationType = goGuidesFetchedData.data.body.destinationType;
      let tmpDestinationName = goGuidesFetchedData.data.body.destinationName;

      //process cms data
      //assign value
      const items = tmpTopDestinations;
      const pageType = tmpPageType;
      const destinationType = tmpDestinationType;
      const destinationName = tmpDestinationName;

      if (checkValue(items) && (items.length > 0)) {
        //prepare prop
        result = {
          items,
          pageType,
          destinationType,
          destinationName,
        };
      }
    }
    return result;
  }

  @action
  public fetchFeaturedStories(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;

    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpFeaturedStories = goGuidesFetchedData.data.body.items;
      let tmpPageType = goGuidesFetchedData.data.body['@type'];
      let tmpDestinationType = goGuidesFetchedData.data.body.destinationType;
      let tmpDestinationName = goGuidesFetchedData.data.body.destinationName;
      let tmpCategoryCode = goGuidesFetchedData.data.body.categoryCode;

      //process cms data
      //assign value
      const items = tmpFeaturedStories;
      const pageType = tmpPageType;
      const destinationType = tmpDestinationType;
      const destinationName = tmpDestinationName;
      const categoryCode = tmpCategoryCode;

      if (checkValue(items) && (items.length > 0)) {
        //prepare prop
        result = {
          items,
          pageType,
          destinationType,
          destinationName,
          categoryCode,
        };
      }
    }
    return result;
  }

  @action
  public fetchKeepExploring(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    let destinationList = null;
    let worldLevel = false;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpDestinationList = goGuidesFetchedData.data.body.destinationList;
      let tmpDestinationId = goGuidesFetchedData.data.body.destinationId;

      //process cms data
      //assign value
      if (checkValue(tmpDestinationList) && Object.keys(tmpDestinationList).length > 0) {
        destinationList = tmpDestinationList;
        if (!checkValue(tmpDestinationId)) {
          worldLevel = true;
        }

      }
      //prepare prop
      result = {
        destinationList,
        worldLevel,
      };
    }
    return result;
  }

  @action
  public fetchOtherCategories(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpDestinationName = goGuidesFetchedData.data.body.destinationName;
      let tmpOtherCategories = goGuidesFetchedData.data.body.otherCategories;

      //process cms data
      //assign value
      const destinationName = tmpDestinationName;
      const categoriesData = tmpOtherCategories;

      if (checkValue(categoriesData) && checkValue(categoriesData.category) &&
          (categoriesData.category.length > 0)) {
        //prepare prop
        result = {
          destinationName,
          categoriesData,
        };
      }
    }
    return result;
  }

  @action
  public fetchContributorsStories(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpContributorsStories = goGuidesFetchedData.data.body.contributorsStories;

      //process cms data
      //assign value
      const storiesData = tmpContributorsStories;

      if (
        checkValue(storiesData) &&
        checkValue(storiesData.storiesList) &&
        (storiesData.storiesList.length > 0)
      ) {
        //prepare prop
        result = {
          storiesData,
        };
      }
    }
    return result;
  }

  @action
  public fetchAlsoPopular(pageUrlData: any) {

    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpParentDestinationName = goGuidesFetchedData.data.body.alsoPopularDestinationName;
      let tmpAlsoPopular = goGuidesFetchedData.data.body.alsoPopular;

      //process cms data
      //assign value
      const parentDestinationName = tmpParentDestinationName;
      const destinationData = tmpAlsoPopular;

      if (checkValue(destinationData) && (destinationData.length > 0)) {
        //prepare prop
        result = {
          parentDestinationName,
          destinationData,
        };
      }
    }
    return result;
  }

  @action
  public fetchOtherNeighbourhoods(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpOtherNeighbourhoods = goGuidesFetchedData.data.body.otherNeighbourhoods2;

      //process cms data
      //assign value
      const neighbourhoodsData = tmpOtherNeighbourhoods;

      if (
        checkValue(neighbourhoodsData) &&
        checkValue(neighbourhoodsData.neighbourhoodsList) &&
        (neighbourhoodsData.neighbourhoodsList.length > 0)
      ) {
        //prepare prop
        result = {
          neighbourhoodsData,
        };
      }
    }
    return result;
  }

  @action
  public fetchFreeText(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpFreeText = goGuidesFetchedData.data.body.freeText;

      //process cms data
      //assign value
      const freeText = tmpFreeText;
      const destinationName = goGuidesFetchedData.data.body.destinationName;

      if (checkValue(freeText)) {
        //prepare prop
        result = {
          freeText,
          destinationName,
        };
      }
    }
    return result;
  }

  @action
  public fetchWhatsAround(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpWhatsAround = goGuidesFetchedData.data.body.whatsAround;
      let tmpDestinationName = goGuidesFetchedData.data.body.destinationName;
      let tmpTravelBlog = goGuidesFetchedData.data.body.travelBlog;

      //process cms data
      //assign value
      const storiesData = tmpWhatsAround;
      const destinationName = tmpDestinationName;
      const travelBlog = tmpTravelBlog;

      if (checkValue(storiesData) && (storiesData.length > 0)) {
        //prepare prop
        result = {
          storiesData,
          destinationName,
          travelBlog
        };
      }
    }
    return result;
  }

  @action
  public fetchCategoryHighLightStories(pageUrlData: any, category: any, maxStories: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;

    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let highLight;
      let tmpPageType = goGuidesFetchedData.data.body['@type'];
      let tmpDestinationName = goGuidesFetchedData.data.body.destinationName;

      if ('THINGS_TO_DO' == category) {
        if (goGuidesFetchedData.data.body.mainHighlight && (goGuidesFetchedData.data.body.mainHighlight.length > 0)) {
          highLight = goGuidesFetchedData.data.body.mainHighlight[0];
          highLight.categoryCode = 'THINGS_TO_DO';
        }
      } else {
        if (
          goGuidesFetchedData.data.body.categoryHighlight &&
          (goGuidesFetchedData.data.body.categoryHighlight.length > 0)
        ) {
          for (const tmpCategory of goGuidesFetchedData.data.body.categoryHighlight) {
            if (tmpCategory.categoryCode == category) {
              highLight = tmpCategory;
            }
          }
        }
      }

      //process cms data
      //assign value
      let categoryData;
      let tmpCategoryHighLightStories;

      if (highLight && highLight.sellAllLink) {
        const adjustedData = {
          ...highLight,
          seeAllLink: highLight.sellAllLink,
        };
        delete adjustedData.sellAllLink;
        tmpCategoryHighLightStories = adjustedData;
        categoryData = tmpCategoryHighLightStories;
      } else {
        categoryData = highLight;
      }

      if (checkValue(categoryData) && Object.keys(categoryData).length > 0) {
        const pageType = tmpPageType;
        const destinationName = tmpDestinationName;

        //prepare prop
        result = {
          categoryData,
          pageType,
          destinationName,
        };
      }
    }
    return result;
  }

  @action
  public fetchWriter(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpWriter = goGuidesFetchedData.data.body.writer;

      //process cms data
      //assign value
      const writer = tmpWriter;

      if (checkValue(writer)) {
        //prepare prop
        result = {
          writer,
        };
      }
    }
    return result;
  }

  @action
  public fetchRelatedStories(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpRelatedStories = goGuidesFetchedData.data.body.relatedStories;
      let tmpListicleItem = goGuidesFetchedData.data.body.items;

      //process cms data
      //assign value
      let showSeeAlso = false;
      if (checkValue(tmpListicleItem) && (tmpListicleItem.length > 3)) {
        showSeeAlso = true;
      }

      if (checkValue(tmpRelatedStories) && (tmpRelatedStories.length > 0)) {
        const relatedStories = tmpRelatedStories;

        //prepare prop
        result = {
          relatedStories,
          showSeeAlso,
        };
      }
    }
    return result;
  }

  @action
  public fetchListicleItems(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpItems = goGuidesFetchedData.data.body.items;
      let tmpFeatures = goGuidesFetchedData.data.body.features;

      //process cms data
      //assign value
      if (checkValue(tmpItems) && (tmpItems.length > 0)) {
        const adjustedData = {
          items: tmpItems.map((item) => {
            const { location, phoneNumber, openingHours, price, geo, nearbyProperties, pdpLink, ...rest } = item;
            let tmpNearbyProperties;
            let tmpPdpLink;
            let tmpInfo = {};

            if (checkValue(location)) {
              tmpInfo['location'] = location
            }
            if (checkValue(phoneNumber)) {
              tmpInfo['phoneNumber'] = phoneNumber;
            }
            if (checkValue(openingHours)) {
              tmpInfo['openingHours'] = openingHours;
            }
            if (checkValue(price)) {
              tmpInfo['price'] = price;
            }
            if (checkValue(geo)) {
              tmpInfo['geo'] = geo;
            }
            if (checkValue(nearbyProperties)) {
              tmpNearbyProperties = { targetUrl: nearbyProperties };
            }
            if (checkValue(pdpLink)) {
              tmpPdpLink = { targetUrl: pdpLink };
            }

            return {
              ...rest,
              ...(tmpInfo && { info: tmpInfo }),
              ...(tmpNearbyProperties && { nearbyProperties: tmpNearbyProperties }),
              ...(tmpPdpLink && { pdpLink: tmpPdpLink }),
            };
          }),
        };

        const items = adjustedData.items;
        const features = tmpFeatures;

        //prepare prop
        result = {
          features,
          items,
        };
      }
    }
    return result;
  }

  @action
  public fetchLocationInfo(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpTitle = goGuidesFetchedData.data.body.h1;
      let tmpInfo = goGuidesFetchedData.data.body.info;

      //process cms data
      //assign value
      if (checkValue(tmpTitle) && checkValue(tmpInfo)) {
        tmpInfo.title = tmpTitle;
        const info = tmpInfo;

        //prepare prop
        result = {
          info,
        };
      }
    }
    return result;
  }

  @action
  public fetchLongContents(pageUrlData: any) {
    const goGuidesFetchedData = this.jsonData;

    let result;
    if (checkValue(goGuidesFetchedData) && checkValue(goGuidesFetchedData.data.body)) {
      let tmpContents = goGuidesFetchedData.data.body.content;

      //process cms data
      //assign value
      if (checkValue(tmpContents) && (tmpContents.length > 0)) {
        const contents = tmpContents;

        //prepare prop
        result = {
          contents,
        };
      }
    }
    return result;
  }

  public generateImageUrl(imageUrl: string) {
    if ("/"==imageUrl[0]) {
      return this.imageDomain + imageUrl
    } else {
      return imageUrl;
    }
  }
}
