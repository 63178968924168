import {codeSplit, Controller, FetchOptions, FetchPageOptions} from 'bernie-core';
import {PageData, Response, AlternateHrefLangLink, PropertyMeta, NamedMeta} from 'bernie-http';
import {checkValue} from 'components/functions';
import {GoGuidesSource} from 'sources';
import {GoGuidesStore} from 'stores/goguides-store';
import {getDomain, getDomainFromUmbracoLocale} from 'src/stores/helpers/data-maps';
import {NOOP_LOGGER} from 'bernie-logger';
import { generateSnippet } from 'stores/helpers/snippet-helper';

export class GoGuidesController implements Controller {
  public pageId = 'GoGuides';
  public path = '/go($|/*.*)';
  public routeName = 'goguides';
  public bundles = [];
  public routeData = {
    rfrrNamespace: 'MESO3.GOGUIDES',
    pageName: 'page.goguides',
  };

  /**
   * Components may be referred to directly
   *   `public component = DestinationHubPage;`
   *
   * Or, the preferred option is to create a code split point that will generate a new bundle.
   *   Use webpack's magic comments `webpackChunkName: "INSERT_FRIENDLY_NAME_HERE"`
   *   This will give it a friendly name for the name of the file.
   */
  public component = codeSplit(() => import(/* webpackChunkName: "goguides" */ '../views/page'));
  public exact = false;

  public pathParams(params: any) {
    /*
     * /go          params is {"0":""}
     * /go/         params is {"0":"/","1":""}
     * /go/thailand params is {"0":"/thailand","1":"thailand"}
     * /goABC       never come to this route
     * */
    let pageUrl = '';
    //check home page

    const param0 = params && params['0'] && params['0'].trim();
    pageUrl = param0;

    if (pageUrl.endsWith('/')) {
      pageUrl = pageUrl.substring(0, pageUrl.length - 1);
    }

    return { pageUrl };
  }

  public async fetch(options: FetchOptions): Promise<PageData> {
    const {
      stores,
      request: { params, context, query },
      response,
    } = options;

    const goGuidesStore = stores.get<GoGuidesStore>('goGuidesStore');

    if (goGuidesStore.isRouteChecked) {
      // this doesn't seems to be working
      return Promise.resolve({}) as Promise<PageData>;
    }

    const { pageUrl } = this.pathParams(params);

    const pageUrlLookup = '/go' + pageUrl;
    const locale = checkValue(query.lang) && query.lang !== '' ? query.lang : context?.locale;
    const skipCache = query.cache

    let domain = getDomain(locale);
    let fallBackLocale = locale;
    if (domain === undefined) {
      // set fallback locale
      goGuidesStore.setIsFallbackLanguage(true);

      fallBackLocale = 'en_GB';
      domain = getDomain(fallBackLocale);
    }
    let umbracoLocale = domain.umbracoLocale;

    const pageData = {
      source: query.source,
      url: pageUrlLookup,
      locale: fallBackLocale,
      umbracoLocale: umbracoLocale,
      skipCache: skipCache,
    };

    const json = await goGuidesStore.fetchPageData(pageData);
    if (checkValue(json) && checkValue(json.data) && checkValue(json.data.body)) {
      goGuidesStore.setPageUrlData(pageData);
      goGuidesStore.setJsonData(json);
      goGuidesStore.setIsRouteChecked(true);
      // return Promise.resolve({});
    } else {
      console.log("ggcontent: 404 from capsapi " + pageData.url, ", locale: " + locale);
      GoGuidesController.redirectResponse(response, '/404/?rfrr=goguides');
    }

    if (checkValue(query.format) && ("json"==query.format)) {
      goGuidesStore.setPageUrlData({contentTypeId: 98});
      return Promise.resolve({}) as Promise<PageData>;
    }

    return Promise.resolve({}) as Promise<PageData>;
  }

  public async fetchPageData(options: FetchPageOptions): Promise<PageData> {
    const { params, query, context, request } = options;
    const ggStore = new GoGuidesStore(new GoGuidesSource(), {}, NOOP_LOGGER );

    const { pageUrl } = this.pathParams(params);
    const pageUrlLookup = '/go' + pageUrl;
    const locale = checkValue(query.lang) && query.lang !== '' ? query.lang : context?.locale;
    const skipCache = query.cache

    /* do we need to handle this test scenario? */

    let domain = getDomain(locale);
    let fallBackLocale = locale;
    if (domain === undefined) {
      // set fallback locale
      /* if it is the different store, this won't need as well */
      ggStore.setIsFallbackLanguage(true);

      fallBackLocale = 'en_GB';
      domain = getDomain(fallBackLocale);
    }
    let umbracoLocale = domain.umbracoLocale;

    const pageData = {
      source: query.source,
      url: pageUrlLookup,
      locale: fallBackLocale,
      umbracoLocale: umbracoLocale,
      skipCache: skipCache,
    };
    let title;
    let description;
    let image;
    const json = await ggStore.fetchPageData(pageData);
    let seo = {}
    if (checkValue(json) && checkValue(json.data) && checkValue(json.data.body)) {
      
      const pageType = json.data.body['@type'];

      const siteName = locale === 'fr_FR' || locale === 'fr_CA' ? ': Guides Go!' : '- Go Guides';
      let tmpDescription;
      let tmpImage;
      
      if (checkValue(json.data.body.introText)) {
         tmpDescription = generateSnippet(json.data.body.introText, 60, json.data.body.locale);
      }
      if (checkValue(json.data.body.image) && checkValue(json.data.body.image.imageUrl)) {
        tmpImage = json.data.body.image.imageUrl;
     }
      
      title = pageType === 'home' 
        ? `${json.data.body.h1} - ${json.data.body.h2}` 
        : `${json.data.body.h1} - ${json.data.body.h2} ${siteName}`;
      description = tmpDescription;
      image = tmpImage;
      
      /* if it is the different store, this won't need as well */
      ggStore.setPageUrlData(pageData);
      ggStore.setJsonData(json);
      ggStore.setIsRouteChecked(true);

      // populate alternate hreflang links and meta elements
      let alternateUrls: AlternateHrefLangLink[] = [];
      let metaTitle = new NamedMeta('title', title);
      let ogMetaTitle = new PropertyMeta('og:title', title);
      let ogMetaDescription = new PropertyMeta('og:description', description);
      let ogMetaType = new PropertyMeta('og:type', 'website');
      let ogMetaImage = new PropertyMeta('og:image', image);

      const canonicalUrl = `${domain.cannoical}${pageUrlLookup}`;
      const xDefaultUrl = `${domain.xDefault}${pageUrlLookup}`;

      if (checkValue(json.data.body.alternateUrls)) {
        const keys = Object.keys(json.data.body.alternateUrls);
        alternateUrls = keys.map((key) => {
          const urlData = pageType === 'home' ? '/go' : `/go${json.data.body.alternateUrls[key]}`;
          const adjustedLocale = getDomainFromUmbracoLocale(key).locale.replace('_', '-');

          return new AlternateHrefLangLink(adjustedLocale, `${getDomainFromUmbracoLocale(key).domain}${urlData}`);
        });
        alternateUrls.push(new AlternateHrefLangLink('x-default', xDefaultUrl));
      }
      let mergeMetaElements = [metaTitle, ogMetaTitle, ogMetaDescription, ogMetaType, ogMetaImage, ...alternateUrls];
      seo = {
        title: title,
        seo: {
          description: description,
          canonical: canonicalUrl,
          additionalMeta: mergeMetaElements,
          robots: '',
        },
      };
    }
    return Promise.resolve({ ...seo }) as Promise<PageData>;
  }

  private static redirectResponse(response: Response, redirectUrl: string) {
    response.redirect(301, redirectUrl);
  }
}
