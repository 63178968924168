/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { ILocalization } from '@shared-ui/localization-context';
// import { DateInput } from 'src/common/__generated__/api/types';
// import { } from 'src/com'
import { useState, useLayoutEffect } from 'react';
// import { utcToZonedTime } from 'date-fns-tz';

export const brandcId = (templateType: string, campaignUrl: string) => {
  let brandcIdVal = '';
  switch (templateType?.toLocaleLowerCase()) {
    case 'spotlight': {
      brandcIdVal = 'BRAND.MESO.MEDIASTUDIO.SPOTLIGHT';
      break;
    }

    default: {
      if (checkValue(campaignUrl)) {
        brandcIdVal = 'BRAND.MESO.MEDIASTUDIO.ACCELERATE';
      }
      break;
    }
  }

  return brandcIdVal;
};

const brands = [
  'expedia',
  'hotels',
  'ebookers',
  'orbitz',
  'cheaptickets',
  'lastminute',
  'mrjet',
  'travelocity',
  'wotif',
  'vrbo',
];
const isExpediaBrand = (link: string) => {
  try {
    const url = new URL(link);
    return brands?.some((b) => url?.host?.indexOf(b) > -1);
  } catch (e) {
    return link?.startsWith('/') ? true : false;
  }
};

export const getTranslateLoc = (feed: any, name: string, defalutValue: string) => {
  return checkValue(feed) && checkValue(feed[name]) ? feed[name] : defalutValue;
};

export const appendMcicid = (
  link: string,
  templateType: string,
  campaignUrl: string,
  mainUrl: string,
  referrerId = ''
) => {
  if (link === '' || link.indexOf('mcicid') > -1) return link;

  if (templateType === 'concierge' && !isExpediaBrand(link)) {
    return link;
  }

  let parameterPrefix = '?';
  const parameterMcicidName = 'mcicid';
  let paramterValuePrefix = 'cp';
  const parameterValueSeparater = '.';
  let paramTemplateType = templateType + parameterValueSeparater;
  // check the templatetype if it is not cplite then it is ( spotlight )
  // So update mcicd prefix
  // This apply only for the subpages
  if (templateType?.toLocaleLowerCase() !== 'cplite') {
    paramterValuePrefix = checkValue(mainUrl) ? `cp.${mainUrl}` : `cp.${templateType}`;
    paramTemplateType = '';
  }

  const referrerIdParameterValue = referrerId != '' ? parameterValueSeparater + referrerId : '';

  if (link.indexOf('?') > -1) parameterPrefix = '&';

  // mcicid tracking
  const mcicidKey =
    parameterMcicidName +
    '=' +
    paramterValuePrefix +
    parameterValueSeparater +
    paramTemplateType +
    campaignUrl +
    referrerIdParameterValue;

  const tracking = parameterPrefix + mcicidKey;

  let url = link;
  let hashTag = '';
  // Check link has hashtag if so add hastag end of the url
  if (checkValue(link) && link?.indexOf('#') > -1) {
    const urlHashArray = link?.split('#');
    url = urlHashArray?.[0];
    hashTag = checkValue(urlHashArray?.[1]) ? '#' + urlHashArray?.[1] : '';
  }

  // Check correct url
  url = url?.startsWith('http') || url?.startsWith('/') ? url : '//' + url;
  return url + tracking + hashTag;
};

export const mcicidFormatText = (templateType: string, campaignUrl: string, mainUrl: string, referrerId = '') => {
  let paramterValuePrefix = 'cp';
  const parameterValueSeparater = '.';
  let paramTemplateType = templateType + parameterValueSeparater;
  // check the templatetype if it is not cplite then it is ( spotlight )
  // So update mcicd prefix
  // This apply only for the subpages
  if (templateType?.toLocaleLowerCase() !== 'cplite') {
    paramterValuePrefix = checkValue(mainUrl) ? `cp.${mainUrl}` : `cp.${templateType}`;
    paramTemplateType = '';
  }

  const referrerIdParameterValue = referrerId != '' ? parameterValueSeparater + referrerId : '';

  return paramterValuePrefix + parameterValueSeparater + paramTemplateType + campaignUrl + referrerIdParameterValue;
};

export const getDefaultValue = (value: any, defaultValue: any = '') => {
  return checkValue(value) ? value : defaultValue;
};

export const checkValue = (value, isCheckLength = false) => {
  let returnVal = false;
  if (value && value !== undefined && value !== null && value !== '') {
    if (isCheckLength) {
      if (value.length > 0) {
        returnVal = true;
      }
    } else {
      returnVal = true;
    }
  }
  return returnVal;
};

export const getCustomScript = (customScript): void => {
  if (checkValue(customScript)) {
    const elCusScript = document.createElement('div');
    elCusScript.setAttribute('id', 'cusScript');
    elCusScript.style.cssText = 'position: absolute;width: 0;height: 0';
    document.getElementsByTagName('body')[0].appendChild(elCusScript);
    const cScript = document.getElementById('cusScript');

    if (checkValue(cScript)) {
      // eslint-disable-next-line no-useless-escape
      let scr = customScript.replace(/\\"/g, '"').replace(/<br\s*\/?>/gi, '');

      scr = scr.toLowerCase().indexOf('<script>') > -1 ? scr : `<script>${scr}</script>`;
      cScript.innerHTML = scr;

      const scripts = cScript.getElementsByTagName('script');
      if (scripts.length > 0) {
        for (const srcipt of scripts) {
          eval(srcipt.innerText);
        }
      }
    }
  }
};

export const LightenDarkenColor = (hex, lum) => {
  if (!hex) {
    return hex;
  }
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // only black color
  hex = hex === '000000' ? '484848' : hex;

  const isLightOrDark = colorHelper.lightOrDark(hex);
  if (isLightOrDark === 'dark') {
    lum = Math.abs(lum);
  } else if (isLightOrDark === 'too-dark') {
    lum = 0.5;
  }
  // convert to decimal and change luminosity
  let rgb = '#',
    c: number | string,
    i: number;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
};

export const colorHelper = {
  lightOrDark: (color) => {
    let r, g, b;

    if (color.match(/^rgb/)) {
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)); // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return 'light';
    } else if (hsp > 50) {
      return 'dark';
    } else {
      return 'too-dark';
    }
  },
};

// crop params remove from Image URL
export const removeImageURLCropParamsAll = (imgUrl) => {
  const img = imgUrl?.split('?');

  return checkValue(img) ? img[0] : '';
};

export const removeImageURLCropParams = (imgUrl) => { 
  if(checkValue(imgUrl) && imgUrl.indexOf('?') > -1 ){
    const tinyImg = imgUrl?.split('?'),
    removedCropTinyImg = tinyImg[1]?.split('&').filter((params) => !params.includes('crop')),
    newHerotinyImage =
      tinyImg[0].length > 0 ? tinyImg[0] + '?crop=&anchor=center&mode=crop&' + removedCropTinyImg?.join('&') : '';

    return newHerotinyImage;
  }else{
    return imgUrl;
  } 
};

export const getQueryParams = (paramKey?: string) => {
  if (typeof window !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (!checkValue(params)) {
      return '';
    }

    return params?.[paramKey];
  } else {
    return '';
  }
};

export const getParameterByName = (name) => {
  if (typeof window !== 'undefined') {
    const url = window?.location?.href;
    if (url === '') {
      return '';
    } else {
      return getQueryParams(name);
    }
  } else {
    return '';
  }
};

export const getLink = (url = '') => {
  if (!checkValue(url?.trim())) {
    return url;
  }
  return url?.toLowerCase().indexOf('http://') > -1 ||
    url?.toLowerCase().indexOf('https://') > -1 ||
    url?.startsWith('/')
    ? url
    : `http://${url}`;
};

export class ItemKeyHelper {
  private itemIndex: number;

  private baseKey: string;

  constructor(baseKey: string) {
    this.itemIndex = 0;
    this.baseKey = baseKey;
  }

  public next() {
    return `${this.baseKey}_${this.itemIndex++}`;
  }
}

export const capitalizeFirst = (str) => {
  const words = str.replaceAll('-', ' ').split(' ');

  words.map((word, index) => {
    words[index] = word.charAt(0).toUpperCase() + word.slice(1);
  });

  return words.join(' ');
};

export const appendMcicidToCmsTypo = (templateType: string, campaignUrl: string, mainUrl: string, referrerId = '') => {
  if (document) {
    setTimeout(() => {
      const allCmsTypo = document.querySelectorAll('.cms-typo a');
      for (let i = 0; i < allCmsTypo.length; i++) {
        const aTag = allCmsTypo[i] as HTMLAnchorElement;
        const aTagHref = aTag.hasAttribute('href');
        const aTagHrefValue = aTag.getAttribute('href');
        if (aTagHref && aTagHrefValue?.toLocaleLowerCase()?.indexOf('mcicid') == -1) {
          const aTagWithMcicid = appendMcicid(aTagHrefValue, templateType, campaignUrl, mainUrl, referrerId);
          aTag.href = aTagWithMcicid;
          const aTagTarget = aTag.getAttribute('target');
          if (!checkValue(aTagTarget)) {
            aTag.target = '_blank';
          }
        }
      }
    }, 1500);
  }
};

export const localization: ILocalization = {
  setLocale: (locale: string) => void 0,
  formatDate: (date: Date, options: any) => {
    if (options && date) {
      const option = options.skeleton || options.raw;
      const locale = options.locale || 'en';

      moment.locale(locale);

      switch (option) {
        case 'MMMEd': {
          return moment(date).format('ddd, MMM D');
        }
        case 'dddDMMM': {
          return moment(date).format('ddd, D MMM');
        }
        case 'yMMMM': {
          return moment(date).format('MMMM YYYY');
        }
        case 'd': {
          return moment(date).format('D');
        }
        case 'EEEEE': {
          return moment(date).format('ddd');
        }
        case 'yyyy-MM-dd': {
          return moment(date).format('YYYY-MM-DD');
        }
        case 'MM/DD/yyyy': {
          return moment(date).format('MM/DD/yyyy');
        }
        case 'MMMd': {
          return moment(date).format('MMM D');
        }
        case 'EEEE': {
          return moment(date).format('ddd');
        }
        case 'MMMM': {
          return moment(date).format('MMMM');
        }
        case 'yMMMMd': {
          return moment(date).format('MMMM D YYYY');
        }
        case 'yMMMd': {
          return moment(date).format('MMM D YYYY');
        }
        case 'dddMMMD': {
          return moment(date).format('ddd, MMM D');
        }
        default: {
          moment(date).format();
        }
      }
    }

    return date && moment(date).format();
  },
  formatDateString: (dateString: string | Date, options: any) => {
    if (options && dateString) {
      const option = options.skeleton || options.raw;

      switch (option) {
        case 'MMMEd': {
          return moment(dateString).format('MMM D');
        }
        case 'yMMMM': {
          return moment(dateString).format('MMMM YYYY');
        }
        case 'yyyy-MM-dd': {
          return moment(dateString).format('YYYY-MM-DD');
        }
        case 'M': {
          return moment(dateString).format('M');
        }
        default: {
          return moment(dateString).format('MMM D');
        }
      }
    }

    return moment(dateString).format('MMM D');
  },
  formatText: (key) => {
    return 'Select Text';
  },
  getWeekData: () => ({ firstDay: 'sun' }),
  parseLocalDateString: (dateString: string, options: any) => {
    if (options && dateString) {
      return moment(dateString, 'YYYY-MM-DD').toDate();
    }

    return moment(dateString, 'YYYY-MM-DD').toDate();
  },
};

export const formatMonth = (month: string | number, options: any) => {
  if (options) {
    const option = options.skeleton || options.raw;
    const locale = options.locale || 'en';

    moment.locale(locale);

    switch (option) {
      case 'MMMM': {
        return moment().month(month).format('MMMM');
      }
      default: {
        return moment().month(month).format('M');
      }
    }
  }
};

export const getQueryVariable = (queryString, variable) => {
  const query = queryString.substring(1);
  const vars = query.split('&');
  if (checkValue(vars, true)) {
    for (const val of vars) {
      const pair = val.split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
  }
  return false;
};

export const defaultHeroImage = () => {
  return [
    {
      name: 'hero-desktop',
      url: 'https://mesocp.azureedge.net/media/kknnrovx/static-image.svg',
    },
    {
      name: 'hero-tablet',
      url: 'https://mesocp.azureedge.net/media/kknnrovx/static-image.svg',
    },
    {
      name: 'hero-mobile',
      url: 'https://mesocp.azureedge.net/media/kknnrovx/static-image.svg',
    },
  ];
};

export const isWizardSupportedLocalization = (local: string) => {
  const wizardNotSupportedLocal = [
    'ar_AE',
    'et_EE',
    'hr_HR',
    'is_IS',
    'lt_LT',
    'lv_LV',
    'pl_PL',
    'ru_RU',
    'sk_SK',
    'tr_TR',
    'he_IL',
    'iw_IL',
  ];
  return !wizardNotSupportedLocal.includes(local);
};

export const instagramImageUrl = (imgUrl: string) => {
  const encodedImage = encodeURIComponent(imgUrl);
  return `https://proxy.elfsightcdn.com/?url=${encodedImage}`;
};

export const getImage = (
  images: Array<{
    name: string;
    url: string;
  }>,
  imageType?:
    | 'desktop'
    | 'desktop-coop'
    | 'desktop-coop-small'
    | 'tablet'
    | 'mobile'
    | 'hero-desktop'
    | 'hero-tablet'
    | 'hero-mobile'
    | 'partner-logo'
    | 'social-share-module'
    | 'large-video-desktop'
    | 'large-video-tablet'
    | 'large-video-mobile'
    | 'small-video-mobile'
    | 'small-video-tablet'
    | 'small-video-desktop'
    | 'wishlist-item-mobile'
    | 'wishlist-item-tablet'
    | 'wishlist-item-desktop'
    | 'large-image-desktop'
    | 'small-image-desktop'
    | 'large-image-mobile'
    | 'small-image-mobile'
) => {
  let image = '';
  if (checkValue(images, true)) {
    if (checkValue(imageType)) {
      const filteredImage = images.filter((img) => img.name.toLocaleLowerCase().includes(imageType));
      image = filteredImage.length > 0 ? filteredImage[0].url : '';
    } else {
      const filteredImage = images[0];
      image = filteredImage[0].url;
    }
  }
  return image;
};

export const getLocalStorageList = (key) => {
  return localStorage.getItem(key);
};

export const removeLocalStorageList = (key) => {
  localStorage.removeItem(key);
};

export const setQueryParamKeyValue = (
  queryKeyValueParams: Array<{ queryParamKey: string; queryParamValue: string }>
): string => {
  if (typeof window !== 'undefined') {
    const windowLocation = window?.location;
    const locationSearch = windowLocation.search;
    let adFilterSearch = locationSearch;

    if (queryKeyValueParams && queryKeyValueParams.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      queryKeyValueParams.forEach((kv: { queryParamKey: string; queryParamValue: string }, i: number) => {
        const { queryParamKey, queryParamValue } = kv;
        const urlSearchParams = new URLSearchParams(locationSearch);
        const params = Object.fromEntries(urlSearchParams.entries());
        const paramsKeyValue = params[queryParamKey];

        if (checkValue(paramsKeyValue)) {
          if (params[queryParamKey] === '') {
            const regex1 = new RegExp([queryParamKey, '='].join(''), 'i'); //i mean case insensitive
            const regexReplace1 = adFilterSearch.replace(regex1, `${queryParamKey}=` + queryParamValue);
            adFilterSearch = regexReplace1;
          } else {
            const regex2 = new RegExp(['(', queryParamKey, '=)[^&]+'].join(''), 'i');
            const regexReplace2 = adFilterSearch.replace(regex2, '$1' + queryParamValue);
            adFilterSearch = regexReplace2;
          }
        } else {
          adFilterSearch =
            adFilterSearch.indexOf('?') > -1
              ? adFilterSearch + `&${queryParamKey}=` + queryParamValue
              : adFilterSearch + `?${queryParamKey}=` + queryParamValue;
        }
      });
    }

    return adFilterSearch;
  } else {
    return '';
  }
};

export const checkVrbo = (siteId: string) => {
  const vrboPos = ['9001001', '9003020', '9003013', '9006043', '9005044'];
  return vrboPos.includes(siteId);
};

export const dateHelper = {
  today: () => {
    return new Date();
  },
  addDate: (dateNumber: number) => {
    const today = dateHelper.today();
    const setDate = dateHelper.today();
    setDate.setDate(today.getDate() + dateNumber);
    return setDate;
  },
  setDate: (date: Date, days: number) => {
    const dateToSetDays = new Date(date);
    dateToSetDays.setDate(date.getDate() + days);
    return dateToSetDays;
  },
  dateFormat: (checkInDate: any, fallbackDate: Date) => {
    try {
      let dateFormatted = checkValue(checkInDate) ? new Date(checkInDate) : fallbackDate;
      // Check date is future
      if (!dateHelper.isDateInFuture(dateFormatted)) {
        dateFormatted = fallbackDate;
      }
      return dateFormatted;
    } catch (e) {
      return null;
    }
  },
  dateInputFormat: (checkInDate: any, fallbackDate: Date) => {
    try {
      const dateFormatted = dateHelper.dateFormat(checkInDate, fallbackDate);
      return {
        day: dateFormatted.getDate(),
        month: dateFormatted.getMonth() + 1,
        year: dateFormatted.getFullYear(),
      };
    } catch (e) {
      return null;
    }
  },
  getDateWithInputFormat: (date: Date) => {
    try {
      return {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };
    } catch (e) {
      return null;
    }
  },
  validDate: (checkInDate: any, today: Date, addDate = 21) => {
    addDate = addDate < 21 ? 21 : addDate;
    let fallbackDate = dateHelper.setDate(today, addDate);
    try {
      if (checkValue(checkInDate)) {
        const newCheckInDate = new Date(checkInDate);
        fallbackDate = dateHelper.diffDays(newCheckInDate, today) < 21 ? fallbackDate : newCheckInDate;
      }

      return fallbackDate;
    } catch (e) {
      return fallbackDate;
    }
  },
  isDateInFuture: (date: Date) => {
    const today = new Date();
    return date > today;
  },
  isAfterDate: (date1: Date, date2: Date) => {
    return new Date(date1) < new Date(date2);
  },
  diffDays: (date1, date2) => {
    return Math.ceil((date1 - date2) / (1000 * 60 * 60 * 24));
  },
  diffHours: (date1, date2) => {
    return Math.ceil((date1 - date2) / (1000 * 60 * 60));
  },
  /**
   *
   * @param fixedDate - CMS setting fixed date check in / check out
   * @param dateString - CMS setting check in / check out it set from 1 weeks from today
   * @returns formatted date string
   */
  getFixedDate: (fixedDate, dateString) => {
    const today = new Date();
    let futureDate = '';
    if (checkValue(dateString)) {
      const noofweeks = checkValue(dateString) ? parseInt(dateString.replace(/\D/g, '')) : 0;
      futureDate = new Date(today.getTime() + noofweeks * 7 * 24 * 60 * 60 * 1000)
        .toLocaleDateString('en-US')
        .concat(' 12:00:00 AM');
    }
    return checkValue(fixedDate) ? fixedDate : futureDate;
  },
  getDateFromWeeksFromToday: (weeksFromToday) => {
    if (checkValue(weeksFromToday)) {
      const getWeeksTextFromToday = weeksFromToday?.replace(' Weeks from Today', '')?.trim();
      if (checkValue(getWeeksTextFromToday)) {
        const getDateToAdded = Math.imul(getWeeksTextFromToday, 7);
        return dateHelper.addDate(Number(getDateToAdded));
      }
    }
    return null;
  },
};

export const filterDataFromList = (list, filterKey: string) => {
  const filteredItemIndex = list.findIndex((el) => checkValue(el[filterKey]));
  if (filteredItemIndex === -1) return list;
  const dataArr = [...list];
  dataArr.splice(0, 1);
  return dataArr;
};
export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const makeHexCode = (code: string) => {
  if (!checkValue(code)) return '';
  if (code.includes('#')) return code;
  return `#${code}`;
};

// export const dealHelper = {
//   getCheckInCheckOutDates: (checkInDate: string, checkOutDate: string) => {
//     const checkInBy14Day = dateHelper.addDate(14);
//     const checkOutBy16Day = dateHelper.addDate(16);
//     const checkInDateFormatted = dateHelper.dateFormat(checkInDate, checkInBy14Day);
//     let checkOutDateFormatted = dateHelper.dateFormat(checkOutDate, checkOutBy16Day);

//     // CheckOut Date is future date than the checkindate
//     if (!dateHelper.isAfterDate(new Date(checkInDateFormatted), new Date(checkOutDateFormatted))) {
//       const newDateForCheckOut = checkInDateFormatted;
//       checkOutDateFormatted = dateHelper.setDate(newDateForCheckOut, 2);
//     }

//     const checkInDateFormattedInputFormat: DateInput = dateHelper.getDateWithInputFormat(checkInDateFormatted);
//     const checkOutDateFormattedInputFormat: DateInput = dateHelper.getDateWithInputFormat(checkOutDateFormatted);

//     return { checkInDateFormattedInputFormat, checkOutDateFormattedInputFormat };
//   },
// };

export const getConciergeCategoryLogo = (categoryName) => {
  if (!checkValue(categoryName)) return '';

  const cardCategoryLogoImages = {
    'adventure-and-outdoors': 'https://mesocp.azureedge.net/media/helata2w/adventure-and-outdoors.svg',
    'arts-and-heritage': 'https://mesocp.azureedge.net/media/zhhd2ntx/arts-and-heritage.svg',
    'attractions-and-sightseeing': 'https://mesocp.azureedge.net/media/diapjcnv/attractions-and-sightseeing.svg',
    'entertainment-and-events': 'https://mesocp.azureedge.net/media/fjnlzbc0/entertainment-and-events.svg',
    'food-drinks-and-dining': 'https://mesocp.azureedge.net/media/ixyl5lro/food-drinks-and-dining.svg',
    guests: 'https://mesocp.azureedge.net/media/o2uckana/guests.svg',
    history: 'https://mesocp.azureedge.net/media/pjvjtby5/history.svg',
    holiday: 'https://mesocp.azureedge.net/media/5tmpmjqo/holiday.svg',
    inclusive: 'https://mesocp.azureedge.net/media/b1nmzn1b/inclusive.svg',
    'length-of-stay': 'https://mesocp.azureedge.net/media/xy3o4mlf/length-of-stay.svg',
    location: 'https://mesocp.azureedge.net/media/tr1a4ezf/location.svg',
    movie: 'https://mesocp.azureedge.net/media/jl3jm2ui/movie.svg',
    'muslim-friendly': 'https://mesocp.azureedge.net/media/ipnao5tq/muslim-friendly.svg',
    'nature-and-wildlife': 'https://mesocp.azureedge.net/media/w4eh5h4k/nature-and-wildlife.svg',
    relaxation: 'https://mesocp.azureedge.net/media/av2p512l/relaxation.svg',
    wellness: 'https://mesocp.azureedge.net/media/hqmdrlnw/wellness.svg',
  };
  return cardCategoryLogoImages[categoryName.toLowerCase()];
};

export const cookieHelper = {
  setCookie: (name: string, value: any, days: number) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  },
  getCookie: (name: string) => {
    if (!checkValue(document)) {
      return null;
    }
    const nameEQ = name + '=';
    const ca = document?.cookie?.split(';');
    for (const element of ca) {
      let c = element;
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  deleteCookie: (name: string) => {
    if (checkValue(document)) {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  },
};

export const getCurlyQuotes = (copy: string) => {
  if (!checkValue(copy)) return '';
  let newCopy = copy;
  let occurance = 1;
  newCopy = [...newCopy]
    .map((char) => {
      if (char === '"' || char === '“') {
        if (occurance % 2 === 1) char = '<span class="replaced-qoutation-mark double-qoutes reverse">,,</span>';
        else char = '<span class="replaced-qoutation-mark double-qoutes">,,</span>';
        occurance += 1;
      }
      return char;
    })
    .join('');
  newCopy = newCopy.replaceAll('’', '<span class=replaced-qoutation-mark>,</span>');
  newCopy = newCopy.replaceAll("'", '<span class=replaced-qoutation-mark>,</span>');
  return newCopy;
};

export const getRandomItemFromList = (list: any) => {
  if (!checkValue(list, true)) return null;
  return list[Math.floor(Math.random() * list.length)];
};

export const toTitleCase = (str: string, replaceHyphen = false) => {
  if (replaceHyphen) str = str.replaceAll('-', ' ');

  return str.toLowerCase().replace(/(?:^|\s)\w/g, (match) => {
    return match.toUpperCase();
  });
};

export const isDateExpired = (currentdate: Date, expirydate, setTime2Zero = false) => {
  const  expiryDate = checkValue(expirydate) ? expirydate : false;
  const timeZone = "America/New_York"
  if (expiryDate) {
    const utcExpiryDateString = expiryDate.toLocaleString('en-US', {timeZone})
    const utcCurrentDateString = currentdate.toLocaleString('en-US', {timeZone})
    const d2 = new Date(utcExpiryDateString);
    const d1 = new Date(utcCurrentDateString); 
    if (setTime2Zero) {
      d1.setHours(0, 0, 0, 0);
      d2.setHours(0, 0, 0, 0);
    }
    return d1 <= d2;
  } else {
    return true;
  }
};


export const quoteAlterations = {
  isQuotesAvailable: (quoteText: string) => {
    return (
      quoteText.slice(0, 1) === '"' ||
      quoteText.slice(-1) === '"' ||
      quoteText.slice(0, 1) === '“' ||
      quoteText.slice(-1) === '”'
    );
  },
  addQuotes: (quoteText: string) => {
    let string = quoteText;
    if (quoteText.slice(0, 1) === '"' || quoteText.slice(0, 1) === '“') {
      string = string.replace(/^.{1}/g, '');
    }
    if (quoteText.slice(-1) === '"' || quoteText.slice(-1) === '”') {
      string = string.replace(/.{1}$/g, '');
    }
    return `
      <span class="quote-one">,,</span>
      ${string}
      <span class="quote-two">,,</span>
    `;
  },
  makeQuoteText: (rawQuoteText: string) => {
    const breakTagRegex = /(<|&lt;)br\s*\/*(>|&gt;)/g;
    const text = rawQuoteText;
    // remove <br /> tags before text
    const beforeTextIndex = text.indexOf('"') !== -1 ? text.indexOf('"') : text.indexOf('“');
   
    let beforeText = '';
    if (beforeTextIndex > 0) {
      beforeText = text.substring(0, beforeTextIndex);
      beforeText = beforeText.replace(breakTagRegex, '');
    }
    // remove <br /> tags after text
    const afterTextIndex = text.lastIndexOf('"') !== -1 ?  text.lastIndexOf('"') : text.indexOf('”');

    let afterText = '';
    if (afterTextIndex > 0) {
      afterText = text.substring(afterTextIndex + 1, text.length);
      afterText = afterText.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, '');
    }

    if (afterTextIndex === -1 || beforeTextIndex === -1) return text;
    // join strings and remove whitespaces from the beginning and end of strings... including newlines.
    return `${beforeText} ${text.substring(beforeTextIndex, afterTextIndex)} ${afterText}`.trim();
  }
}

export const getUserTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDateStringToIsoFormat = (dateString , eventT) => {
  const [datePart, timePart, ampm] = dateString.split(' ');
  const [month, day, year] = datePart.split('/');
  const [hour, minute] = timePart.split(':');
  const monthString = parseInt(month).toString().padStart(2, '0');
  const dayString = parseInt(day).toString().padStart(2, '0');
  let hourString = hour.toString().padStart(2, '0');
  if (ampm === 'AM' && hour === '12') {
    hourString = '00';
  } else if (ampm === 'PM' && hour !== '12') {
    hourString = (parseInt(hour) + 12).toString();
  }
  const minuteString = parseInt(minute).toString().padStart(2, '0');
  const timeZoneString = eventT === "EDT" ? `-04:00` : "-05:00"
  const dateTime = `${year}-${monthString}-${dayString}T${hourString}:${minuteString}:00${timeZoneString}`;
  return dateTime;
};

// export const convertEventTime = (eventDate, eventT) => {
//   const userTimeZone = getUserTimeZone();
//   const eventDateTime = getDateStringToIsoFormat(eventDate , eventT);
//   const userZonedTime = utcToZonedTime(eventDateTime, userTimeZone);
//   return userZonedTime
// };

export const getUniqueListBy = (arr: any, key: string) => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export const breakWordwithSPChar = (word, spchar = "&shy;") => {
  const isWordConatainsHash = word.includes("#");
  const index = 8;

  if (isWordConatainsHash) {
    console.log(word.replace(/#/g, spchar) , "word.replace(/#/g, spchar)" , word)
    return word.replace(/#/g, spchar);
  } else if (!isWordConatainsHash && index >= 0 && index <= word.length) {
    const firstPart = word.slice(0, index);
    const secondPart = word.slice(index);
    return firstPart + spchar + secondPart;
  } else {
    return word;
  }
};
