import {Logger, NOOP_LOGGER} from "bernie-logger";
import {checkValue} from "components/functions";
import {catHubData} from "components/mock-data/cathub";
import {dhubData} from "components/mock-data/dhub";
import {homeData} from "components/mock-data/home";
import {listicleData} from "components/mock-data/listicle";
import {longFormData} from "components/mock-data/longform";
import {GoGuidesUrlProps} from "components/types/goguides-types.d";
import {config} from "config/config";
import fetch from "isomorphic-fetch";
import {domainDictionary} from "stores/helpers/data-maps";

const GG_CMS_API = config?.caps_api;
export class GoGuidesSource {
  private readonly logger: Logger;

  constructor(logger: Logger = NOOP_LOGGER) {
    this.logger = logger;
  }

  public extractPageUrl(pageUrlData: any): GoGuidesUrlProps {
    const pageUrl = pageUrlData.url;
    const locale = pageUrlData.locale;

    let domainUrl = domainDictionary[locale];
    let domain = domainUrl.domain;
    let queryString = domainUrl.queryString;
    let umbracoLocale = domainUrl.umbracoLocale;
    return {domain, pageUrl, queryString, locale, umbracoLocale};
  }

  public async fetchDDAPageDataOffline(
    pageUrl: any,
  ) {
    let json = homeData[pageUrl];
    if (checkValue(json)) return json;

    json = dhubData[pageUrl];
    if (checkValue(json)) return json;

    json = catHubData[pageUrl];
    if (checkValue(json)) return json;

    json = listicleData[pageUrl];
    if (checkValue(json)) return json;

    json = longFormData[pageUrl];
    if (checkValue(json)) return json;

    return null;
  }

  public async fetchCMSPageDataByPageUrl(pageUrlData: any) {
    const {domain, pageUrl, queryString, umbracoLocale} = this.extractPageUrl(pageUrlData);
    const source = pageUrlData.source;
    let goGuidesFetchedData;
    if ("dda"==source) {
      if (checkValue(domain)) {
        goGuidesFetchedData = await this.fetchDDAPageDataOnline(domain, pageUrl, queryString).then((data) => {
          return data;
        });
      }
    } else

    if ("json"==source) {
      goGuidesFetchedData = await this.fetchDDAPageDataOffline(pageUrl);
    } else {
      let url = pageUrlData.url;
      let apiUrl;
      if ("/go"==url) {
        apiUrl = "/goguides/home"
      } else {
        apiUrl = url.replace("/go/", "/goguides/");
      }

      let extraParam = "";
      let checkEnv = "";
      try {
        if (
          (typeof process  != 'undefined')
          && (typeof process.env  != 'undefined')
          && (typeof process.env.EXPEDIA_ENVIRONMENT  != 'undefined')
        ) {
          checkEnv = process.env.EXPEDIA_ENVIRONMENT
        }
      } catch(e) {console.log(e);}
      if ("test" == checkEnv) {
        // extraParam = "&ispreview=1"
      }

      if ("false" == pageUrlData.skipCache) {
        extraParam = "&ispreview=1"
      }

      goGuidesFetchedData = await this.fetchUmbracoCMSPageData(
        "https://uk.hotels.com/capsapi"
        , apiUrl
        , "locale=" + umbracoLocale + extraParam).then((data) => {
        return data;
      });
    }
    return goGuidesFetchedData;
  }

  public async fetchDDAPageDataOnline(domain: string, pageUrl: string, queryString: string) {
    const url = domain + pageUrl + '?format=json&' + queryString;
    console.log("ggcontent: fetch content from " + url);

    const result = await fetch(
      url,
      {
        mode: 'no-cors',
        method: 'GET',
      })
      .then(
        (res) =>
          res.json().then((response) => {
            return response;
          })
      )
      .catch((err) => ({
        error: err,
      }));

    let data;
    if (checkValue(result)
      && checkValue(result.data?.page?.html?.locale)
      && checkValue(result.data?.body?.locale)) {
      data = result;
    }

    return data;
  }

  public async fetchUmbracoCMSPageData(domain: string, pageUrl: string, queryString: string) {
    // const url = GG_CMS_API + pageUrl + '?' + queryString;
    const url = domain + pageUrl.toLowerCase() + '?' + queryString;
    console.log("fetch content from " + url);

    const result = await fetch(
      url,
      {
        mode: 'no-cors',
        method: 'GET',
      })
      .then(
        (res) =>
          res.json().then((response) => {
            return response;
          })
      )
      .catch((err) => ({
        error: err,
      }));

    let data;
    if (checkValue(result)
      && checkValue(result.id)
      && checkValue(result.name)
      && checkValue(result.contentType)) {
      data = result;
    }

    return data;
  }

  public async fetchCMSPageData(
    templateName: string,
    feedName: string,
    pagelang: string,
    siteId: string,
    isCacheFeed: boolean
  ) {
    let isPreview = '';
    if (!isCacheFeed) {
      isPreview = '?ispreview=1';
    }

    const campFeedName = feedName !== '' ? feedName + '/' : '';
    const url = GG_CMS_API + templateName + '/' + campFeedName + pagelang + '/' + siteId + isPreview;
    //const url = 'http://localhost:3000/capsapi/template-cplite/heritage/en_US/1?ispriview=1';
    return await fetch(url, {
      method: 'GET',
    })
      .then((res) =>
        res.json().then((response) => {
          console.log("responseresponseresponse " + JSON.stringify(response));
          return response;
        })
      )
      .catch((err) => ({
        error: err,
      }));
  }

}
