export const loadBrandData = async brand => {
    
    const brandHash = (brand: string) => {
    if (!brand) {
      return '';
    }
  
    const code = Array.from(brand)
      .map((c) => c.charCodeAt(0))
      .join('');

    return "brand-" + code;
  };

    const brandMap = {
      "brand-1171001159510010110297117108116": async () => await import(/* webpackChunkName: "brand-1171001159510010110297117108116" */ "./uds_default"),"brand-104111116101108115": async () => await import(/* webpackChunkName: "brand-104111116101108115" */ "./hotels")
    };
    const brandIndex = brandHash(brand);

    if (brandIndex in brandMap) {
      return await brandMap[brandIndex]();
    } else {
      return await brandMap['brand-1171001159510010110297117108116']();
    }
  };
  